import React, { Fragment } from "react";

import { useTranslation } from "react-i18next";

import { Menu, Transition } from "@headlessui/react";
import { SvgDotsMenu } from "@routes/IconeSvg";

/**
 *
 * @param {object} props
 * @param {Array} props.lists
 * @param {boolean=} props.withIcon
 * @param {function=} props.onOpenCloseMenu
 * @param {String=} props.parentClass
 * @param {boolean=} props.locked
 * @returns
 */
const Dropdown = ({
  lists,
  withIcon = false,
  onOpenCloseMenu,
  parentClass = "",
  locked = false
}) => {
  const { t } = useTranslation("components.menu");

  return (
    <div className={parentClass}>
      <Menu as="div" className="arh-relative arh-inline-block">
        {({ open }) => {
          if (onOpenCloseMenu) onOpenCloseMenu(open);
          return (
            <>
              <Menu.Button disabled={locked} className={`${locked && "arh-cursor-default"}
                arh-flex arh-h-8 arh-w-8 arh-items-center arh-justify-center arh-rounded-full arh-border-0 arh-bg-transparent hover:arh-bg-gray-200 active:arh-bg-greyActive
              `}>
                <SvgDotsMenu />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="arh-transition ease-out arh-duration-100"
                enterFrom="arh-transform opacity-0 arh-scale-95"
                enterTo="arh-transform arh-opacity-100 arh-scale-100"
                leave="arh-transition arh-ease-in arh-duration-75"
                leaveFrom="arh-transform arh-opacity-100 arh-scale-100"
                leaveTo="arh-transform arh-opacity-0 arh-scale-95"
              >
                <Menu.Items className="arh-focus:outline-none arh-absolute arh-right-0 arh-z-10 arh-w-fit arh-min-w-[228px] arh-rounded-md arh-bg-white arh-p-4 arh-shadow-xl">
                  {lists.map((_list) => {
                    return (
                      <Menu.Item
                        as="div"
                        onClick={_list.click}
                        // toggle={false}
                        key={_list.title}
                        disabled={_list?.disabled || false}
                        id={_list.id || "id"}
                        ref={_list.ref || null}
                      >
                        <div className={`arh-flex arh-cursor-pointer arh-items-center arh-justify-start arh-space-x-2 arh-rounded arh-p-2 hover:arh-bg-gray-200 active:arh-bg-greyActive`}>
                          {withIcon && <span> {_list.icon} </span>}

                          <span className="arh-font-poppins arh-text-xs arh-font-medium arh-text-black">
                            {t(_list?.title)}
                          </span>
                        </div>
                      </Menu.Item>
                    );
                  })}
                </Menu.Items>
              </Transition>
            </>
          );
        }}
      </Menu>
    </div>
  );
};
export default React.memo(Dropdown);
