/*eslint dot-location: "off"*/
import moment from "moment-timezone";

import { PERMISSION_ADMIN } from "@helpers/_constants";
import {
  compareEmployersFullName,
  getTimezoneName,
  getDateUtcZero,
  getDateToRender
} from "@helpers/_functions";
import {
  News,
  Employer,
  Enterprise,
  Users,
  HoraireService,
  EmployerSalary,
  Conge,
  EmployerEmplacement,
  EmployerGroup,
  WorkingHours,
  Emplacement,
  EmployerHours,
  TimeClock,
  DataFile,
  Material,
  Project,
  Formation,
  DayOff,
  EmployeurDService
} from "@services/all.services";
import { CalendarUtils } from "@utils/CalendarUtils";
import { EmployeUtils } from "@utils/EmployeUtils";
import { createEventFromHours } from "@utils/calendar.utils";

let enterpriseInfo = [];

//Get All News Enterprise
export const allNewsDataEnterprise = async (params) => {
  if (localStorage.getItem("token")) {
    let idEmployer = localStorage.getItem("userId");
    const response = (await News.getByEmployer(idEmployer, params))?.data
      ?.data?.[0];
    if (response) {
      return [response.docs, response.count];
    }
  }
};

export const allNewsTagDataEnterprise = async () => {
  if (localStorage.getItem("token")) {
    const enterpriseId = localStorage.getItem("enterpriseId");
    const response = await News.getNewsTagByEntrepriseId(enterpriseId);
    if (response) {
      return response;
    }
  }
};

export const allDayOffEnterprise = async (params) => {
  if (localStorage.getItem("token")) {
    const enterpriseId = localStorage.getItem("enterpriseId");
    const response = (await DayOff.getDayOffByEnterprise(enterpriseId))?.data
      ?.data?.[0];
    if (response) {
      return response;
    }
  }
};

//Get Employer Information
export const infoEmployer = async () => {
  if (localStorage.getItem("token")) {
    try {
      const getUserResponse = await Users.getUserConnected(
        localStorage.getItem("userId")
      );
      const userData = getUserResponse?.data?.data;
      // if (!userData?.Role?.name) {
      //   throw new Error('no user found');
      // }

      let getEmployeResponse = null;
      getEmployeResponse = await Employer.getById(userData?._id);
      const employerInfo = getEmployeResponse?.[0]?.docs?.[0];
      if (!employerInfo) {
        throw new Error("info employer introuvable");
      }
      const personnalInformation = employerInfo.personnalInformation[0];
      const result = {
        employerName:
          personnalInformation.legalFirstName +
          " " +
          personnalInformation?.legalName,
        idEmployer: employerInfo._id,
        profilImgConnected: employerInfo.images?.[0]?.location,
        profilImageData: employerInfo.images[0],
        personnalInformation,
        adress: employerInfo.adress[0],
        emails: employerInfo.emails[0],
        firstEmergencyContact: employerInfo.firstEmergencyContact[0],
        secondEmergencyContact: employerInfo.secondEmergencyContact[0],
        status: employerInfo.status,
        language: userData.language,
        allergies: employerInfo.allergies || [],
        foodPreferences: employerInfo.foodPreferences || []
      };
      return result;
    } catch (err) {
      console.error("getting employe info error: ", err);
      const result = {
        employerName: "Erreur erreur",
        idEmployer: null,
        profilImgConnected: null,
        profilImageData: null,
        personnalInformation: null,
        adress: null,
        emails: null,
        firstEmergencyContact: null,
        secondEmergencyContact: null,
        status: null
      };
      return result;
    }
  }
};

//Get Enterprise Information
export const infoEnterprise = async () => {
  if (localStorage.getItem("token")) {
    const res = await Users.getUserConnected(localStorage.getItem("userId"));
    if (res?.data?.data) {
      let dataUsers = res.data.data;
      if (dataUsers?.Role?.name === PERMISSION_ADMIN) {
        const response = await Enterprise.listByUser(
          localStorage.getItem("userId")
        );
        if (response?.data?.data?.[0]) {
          enterpriseInfo = response.data.data[0].enterpriseName;
          return enterpriseInfo;
        }
      } else {
        const response = await Employer.getById(localStorage.getItem("userId"));
        let dataResponse = response?.[0]?.docs?.[0];
        if (dataResponse) {
          const reponse = await Enterprise.listByUser(dataResponse?.User?.[0]);
          if (reponse?.data?.data?.[0]) {
            enterpriseInfo = reponse.data.data[0].enterpriseName;
            return enterpriseInfo;
          }
        }
      }
    }
  }
};

//Get Employer Shift
//Cette fonction est utilisé par getEmployerHoursByEnterprise (ci dessous)
//Si vous voulez le supprimer, merci de le prendre en compte
export const horaryByEmployer = async (userId = null) => {
  if (localStorage.getItem("token")) {
    try {
      userId = userId || localStorage.getItem("userId");
      const response = await HoraireService.getByEmployer(userId);
      if (response?.data?.data) {
        return response.data.data;
      }
    } catch (err) {
      console.log(err);
    }
  }
};

export const getHoraryOneMonth = async (date) => {
  if (localStorage.getItem("token")) {
    try {
      const params = {
        employerId: localStorage.getItem("userId"),
        date: date
      };
      const responses = await HoraireService.getOneMonthByEmployer(params);
      if (responses) {
        return responses.data.data;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  }
};

export const getHoraryOneMonthByEnteprise = (date) =>
  new Promise(async (resolve, reject) => {
    if (localStorage.getItem("token")) {
      try {
        const enterpriseId = localStorage.getItem("enterpriseId");
        const params = {
          enterpriseId,
          date
        };
        const responses = await HoraireService.getOneMonthByEnterprise(params);
        resolve(responses.data?.data);
      } catch (err) {
        console.log(err);
        reject(false);
      }
    }
  });

export const getHoraryOneWeek = async (date) => {
  if (localStorage.getItem("token")) {
    try {
      const params = {
        employerId: localStorage.getItem("userId"),
        date: date
      };
      const responses = await HoraireService.getOneWeekByEmployer(params);
      if (responses) {
        return responses.data.data;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  }
};

export const getHoraryOneWeekByEnterprise = async (date) => {
  if (localStorage.getItem("token")) {
    try {
      const enterpriseId = localStorage.getItem("enterpriseId");
      const params = {
        enterpriseId,
        date
      };
      const responses = await HoraireService.getOneWeekByEnterpriseId(params);
      if (responses) {
        return responses.data.data;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  }
};

export const getHoraryOneDay = async (date) => {
  if (localStorage.getItem("token")) {
    try {
      const params = {
        employerId: localStorage.getItem("userId"),
        date: date
      };
      const responses = await HoraireService.getOneDayByEmployer(params);
      if (responses) {
        return responses.data.data;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  }
};
// End get Employer Shift

//Get Employer Congé Info
export const getEmployerConge = async (queryParams) => {
  if (localStorage.getItem("userId")) {
    try {
      const conges = await Conge.getByEmployer(false, queryParams);
      return conges;
    } catch (error) {
      console.error(
        "Error when getting list of employer conges! More info :\n",
        error
      );
    }
  }
};

//Get Employer Emplacement
export const getEmployerEmplacement = async (employerId = null) => {
  if (localStorage.getItem("token")) {
    try {
      if (!employerId) {
        employerId = localStorage.getItem("userId");
      }
      const response = await EmployerEmplacement.getByEmployer(employerId);
      if (response?.data?.data) {
        const emplacements = response?.data?.data?.[0]?.Emplacement;
        return emplacements || [];
      }
    } catch (err) {
      console.log(err);
    }
  }
};

//Get Employer Groupe
export const getEmployerGroup = async (employerId) => {
  if (localStorage.getItem("token")) {
    if (!employerId) {
      employerId = localStorage.getItem("userId");
    }
    const response = await EmployerGroup.listByEmployer(employerId);
    if (response?.data?.data) {
      return response.data.data;
    }
  }
};

//Get Employer Working Hours
export const getEmployerWorkingHours = async () => {
  if (localStorage.getItem("token")) {
    const employerId = localStorage.getItem("userId");
    const response = await WorkingHours.getByEmployer(employerId);
    if (response?.data?.data) {
      return response.data.data;
    }
  }
};

//Get Employer Salary by User
export const getEmployerSalaryByUser = async (dataAfter) => {
  if (localStorage.getItem("token")) {
    try {
      let dataUsers = (
        await Users.getUserConnected(localStorage.getItem("userId"))
      )?.data?.data;
      if (dataUsers) {
        let params = {
          dataAfter
        };
        if (
          dataUsers?.accessList?.includes("isAdmin") ||
          dataUsers?.Role?.name === PERMISSION_ADMIN
        ) {
          const response = (
            await Employer.getEmploiSalaireByUserId(
              localStorage.getItem("userId"),
              params
            )
          )?.data?.data?.[0];
          return [response?.docs, response?.count];
        } else {
          const employer = (
            await Employer.getById(localStorage.getItem("userId"))
          )?.[0]?.docs?.[0];
          if (employer) {
            const reponse = (
              await Employer.getEmploiSalaireByUserId(employer.User[0], params)
            )?.data?.data?.[0];
            return [reponse?.docs, reponse?.count];
          }
        }
      }
    } catch (err) {
      console.error("Erreur chargement liste employés => ", err);
      return [];
    }
  }
};
// Get Employer Salary by Employer
export const getEmployersalaryByUserConnected = async () => {
  if (localStorage.getItem("token")) {
    try {
      let dataSalaryOfTheEmployerConnected = await EmployerSalary.getByEmployer(
        localStorage.getItem("userId")
      );
      if (!dataSalaryOfTheEmployerConnected) {
        console.error(" erreur de chargement de l'emploi et salaire");
      }
      return dataSalaryOfTheEmployerConnected || {};
    } catch (err) {
      console.error("Erreur chargement de l'emploi et salaire => ", err);
      return [];
    }
  }
};

//Get Emplacement by Enterprise
export const getEmplacementByEnterprise = async (queryParams) => {
  if (localStorage.getItem("token")) {
    let emplacements = [];
    let count = 0;
    try {
      const getEmplacementResponse = await Emplacement.getByEnterprise(
        localStorage.getItem("enterpriseId"),
        queryParams
      );
      if (!getEmplacementResponse?.[0]?.docs) {
        throw new Error("no emplacement found");
      }
      emplacements = getEmplacementResponse?.[0]?.docs;
      count = getEmplacementResponse?.[0]?.count;
    } catch (err) {
      console.error("error loading emplacement =>", err);
    }
    return { emplacements, count };
  }
};

//Get List Employer
export const getListEmployer = async (filter, queryParams) => {
  if (localStorage.getItem("token")) {
    let docs = [];
    let count = 0;
    const user = localStorage.getItem("idAdminEnterprise");
    try {
      let employers = await Employer.getListEmployerFiltered(
        user,
        filter,
        queryParams
      );
      if (!employers?.data?.data) {
        throw new Error("unexpected response");
      }
      docs = employers?.data?.data || [];
      count = employers?.data?.data?.length || 0;
    } catch (err) {
      console.log(err);
    }
    return { docs, count };
  }
};

//Get Working hours summary to Export
export const getWorkingHoursSummaryForExport = async (filter, queryParams) => {
  if (localStorage.getItem("token")) {
    let workingHoursForExport = [];
    const user = localStorage.getItem("idAdminEnterprise");
    try {
      let employers = await Employer.getWorkingHoursSummaryToExport(
        user,
        filter,
        queryParams
      );
      if (!employers?.data?.data) {
        throw new Error("unexpected response");
      }
      workingHoursForExport = employers?.data?.data || [];
    } catch (err) {
      console.log(err);
    }
    return workingHoursForExport;
  }
};

// Temp get employeurD working hours
export const getWorkingHoursEmployeurD = async (queryParams) => {
  if (localStorage.getItem("token")) {
    let workingHoursForExport = [];
    const user = localStorage.getItem("idAdminEnterprise");
    try {
      let employers = await EmployeurDService.getWorkingHours(
        user,
        queryParams
      );
      if (!employers?.data?.data) {
        throw new Error("unexpected response");
      }
      workingHoursForExport = employers?.data?.data || [];
    } catch (err) {
      console.log(err);
    }
    return workingHoursForExport;
  }
};

//Get Enterprise Shift
export const horaryByEnterprise = async () => {
  if (localStorage.getItem("token")) {
    try {
      const enterpriseId = localStorage.getItem("enterpriseId");
      const response = await HoraireService.getByEnterprise(enterpriseId);
      if (response) {
        return response;
      }
    } catch (err) {
      console.log(err);
    }
  }
};

//Get Users Connected
export const getUserConnected = async () => {
  if (localStorage.getItem("token")) {
    const res = await Users.getUserConnected(localStorage.getItem("userId"));
    if (res?.data?.data) {
      return res.data.data;
    }
  }
};
//Get Congé by Enterprise
export const getCongeByEnterprise = async (dataAfter) => {
  if (localStorage.getItem("token")) {
    const result = await Conge.getByEnterprise(
      localStorage.getItem("enterpriseId"),
      dataAfter
    );
    if (result) {
      return result;
    }
  }
};

// deprecated
export const getGroupsAndEmplacementNames = (data = []) => {
  let result = data;
  if (result?.length) {
    for (let value of data) {
      if (value) {
        let groupeName = [];
        for (let res of value?.groupOfEmployer) {
          if (value?.groupOfEmployer) {
            if (!groupeName.includes(res?.groupOfEmployer)) {
              groupeName.push(res?.groupOfEmployer);
            }
          }
        }
        value.groupeName = groupeName?.length ? groupeName.join(", ") : "Aucun";
      }
    }
  }
  return result;
};

// deprecated
export const getPointingCorrectionDataByEnterprise = async (queryParams) => {
  if (localStorage.getItem("token")) {
    const response = await EmployerHours.getPointingCorrectionByEnterprise(
      localStorage.getItem("idAdminEnterprise"),
      queryParams
    );
    const count = response?.[0]?.count || 0;
    const employeHours = response?.[0]?.docs || [];
    return { employeHours, count };
  }
};

function splitDatesIntoShifts(listDatesAgenda) {
  const shifts = [];
  let events = [];

  listDatesAgenda.forEach((agenda) => {
    if (agenda.lunchHour?.startLunch && agenda.lunchHour?.endLunch) {
      events.push({
        ...agenda,
        endDate: agenda.lunchHour.startLunch,
        lunchHour: null
      });
      events.push({
        ...agenda,
        startDate: agenda.lunchHour.endLunch,
        lunchHour: null
      });
    } else {
      events.push({ ...agenda, lunchHour: null });
    }
  });

  events.forEach((event) => {
    let current = moment.tz(event.startDate, event.timezone);
    const end = moment.tz(event.endDate, event.timezone);

    while (current.isBefore(end)) {
      let next = current.clone().endOf("day");

      if (next.isAfter(end)) {
        next = end.clone(); // Ajuste la dernière date si elle dépasse endDate
      }

      shifts.push({
        ...event,
        startDate: current.format(),
        endDate: next.format()
      });

      // Passe à la prochaine journée
      current = next.clone().startOf("day").add(1, "day");
    }
  });

  return shifts;
}

export const getEmployerHoursByEnterprise = async (queryParams) => {
  if (localStorage.getItem("token")) {
    const response = await EmployerHours.getByEnterprise(
      localStorage.getItem("idAdminEnterprise"),
      queryParams
    );
    const count = response?.[0]?.count || 0;
    const employeHours = response?.[0]?.docs || [];
    return { employeHours, count };
  }
};

function generateDateList(startDate, endDate) {
  const dateList = [];

  let currentDate = startDate;

  while (currentDate?.isSameOrBefore(endDate)) {
    dateList.push(currentDate.toDate());
    currentDate = currentDate.add(1, "day");
  }

  return dateList;
}

export const getEmployerHoursByEmployerAndDate = async (
  idEmployer,
  queryParams
) => {
  if (localStorage.getItem("token")) {
    const response = await EmployerHours.getByEmployerAndDate(idEmployer, {
      ...queryParams,
      localTimezone: moment.tz.guess(),
      enterpriseId: localStorage.getItem("enterpriseId")
    });
    let responses = response.data.data;

    let filters = {
      ...queryParams
    };

    let employeeHour = responses?.[0];
    if (employeeHour.agenda.length) {
      employeeHour.agenda = splitDatesIntoShifts(employeeHour.agenda);
    }
    employeeHour.date = generateDateList(
      moment(filters.startFilter),
      moment(filters.endFilter)
    );
    let tempConges = [];
    let employeeHours = employeeHour?.date.reduce((acc, date) => {
      // create a composed key: 'year-week'
      const yearWeek = `${moment(date).year()}-${moment(date).isoWeek()}`;

      // add this key as a property to the result object
      if (!acc[yearWeek]) {
        acc[yearWeek] = [];
      }
      const dateMoment = moment(date);
      const _id = dateMoment.format("YYYY-MM-DD");
      const agenda = employeeHour.agenda.filter((hour) => {
        const hourStartDate = hour.timezone
          ? moment(
              moment
                .tz(hour.startDate, hour.timezone)
                .format("YYYY-MM-DD HH:mm")
            )
          : moment(hour.startDate);
        if (hourStartDate.isSame(dateMoment, "day")) {
          return hour;
        } // false, different month
      });
      const Conges = employeeHour.Conges.reduce((result, conge) => {
        let found = tempConges.filter((temp) => temp._id === conge._id);
        if (!found.length) {
          const timezone = conge.timezone || "utc";
          let congeStart = getDateToRender(conge.start, timezone);
          let congeEnd = getDateToRender(conge.end, timezone);
          if (
            !(
              dateMoment.endOf("day").isSameOrBefore(congeStart) ||
              dateMoment.startOf("day").isSameOrAfter(congeEnd)
            )
          ) {
            tempConges.push({ ...conge, start: congeStart, end: congeEnd });
            result.push({ ...conge, start: congeStart, end: congeEnd });
          }
        }
        return result;
      }, []);
      const WorkingHours = employeeHour.WorkingHours.filter((workingHour) => {
        const workingHourStart = workingHour.Timezone
          ? moment(
              moment
                .tz(workingHour.startDate, workingHour.Timezone)
                .format("YYYY-MM-DD HH:mm")
            )
          : moment(workingHour.startDate);
        if (workingHourStart.isSame(dateMoment, "day")) {
          return workingHour;
        }
      });

      const shifts = [];
      Conges.forEach((event) => {
        let current = moment(event.start);
        const end = moment(event.end);

        while (current.isBefore(end)) {
          let next = current.clone().endOf("day");

          if (next.isAfter(end)) {
            next = end.clone(); // Ajuste la dernière date si elle dépasse endDate
          }

          shifts.push({
            ...event,
            start: current.format(),
            end: next.format()
          });

          // Passe à la prochaine journée
          current = next.clone().startOf("day").add(1, "day");
        }
      });

      let conges = [];
      shifts.forEach((conge) => {
        let date = conge.start;
        while (
          moment(date).format("YYYY-MM-DD") <=
          moment(conge.end).format("YYYY-MM-DD")
        ) {
          conges.push({ ...conge, date: date });
          date = moment(date).add(1, "day").toDate();
        }
      });

      // push the current data in a date that belongs to the year-week calculated before
      acc[yearWeek].push({
        _id,
        agenda,
        Conges: conges,
        WorkingHours,
        dateAtMonth: moment(date).month(),
        dateAtWeek: moment(date).isoWeek(),
        year: moment(date).year(),
        personnalInformation: employeeHour.personnalInformation,
        User: employeeHour.User
      });
      return acc;
    }, {});
    employeeHours = Object.values(employeeHours || {}).map((employeeHour) => {
      return {
        week: employeeHour
      };
    });
    let greatTotal = {
      totalAgenda: "0",
      totalWorking: "0",
      hoursAgenda: 0,
      minutesAgenda: 0,
      hoursWorking: 0,
      minutesWorking: 0,
      idEmployer: idEmployer
    };
    let allResPonse = employeeHours;
    /** @type {{month: any[]}[]} */
    let allResult = [];
    if (allResPonse?.length === 1) {
      let actualMonthDayNumber = [];
      let nextMonthDayNumber = [];
      for (let weekUnit of allResPonse[0].week) {
        if (
          !actualMonthDayNumber.length ||
          actualMonthDayNumber.some(
            (el) => el === moment(weekUnit._id).format("MM")
          )
        ) {
          actualMonthDayNumber.push(moment(weekUnit._id).format("MM"));
        } else {
          nextMonthDayNumber.push(moment(weekUnit._id).format("MM"));
        }
      }
      let weekOnActualMonth = {};
      if (actualMonthDayNumber.length > nextMonthDayNumber.length) {
        weekOnActualMonth = {
          monthNumber: actualMonthDayNumber[0],
          week: allResPonse[0].week
        };
      } else {
        weekOnActualMonth = {
          monthNumber: nextMonthDayNumber[0],
          week: allResPonse[0].week
        };
      }
      if (!allResult[0]) {
        allResult[0] = { month: [] };
      }
      allResult[0].month.push(weekOnActualMonth);
    } else {
      for (let responseUnit of allResPonse) {
        let dayWeekOnActualMonth = [];
        let dayWeekOnNextMonth = [];
        for (let weekUnit of responseUnit.week) {
          if (
            !dayWeekOnActualMonth.length ||
            dayWeekOnActualMonth.some(
              (el) =>
                moment(el._id).format("MM") ===
                moment(weekUnit._id).format("MM")
            )
          ) {
            dayWeekOnActualMonth.push(weekUnit);
          } else {
            dayWeekOnNextMonth.push(weekUnit);
          }
        }

        let weekOnActualMonth = {
          monthNumber: moment(dayWeekOnActualMonth?.[0]?._id).format("MM"),
          week: dayWeekOnActualMonth
        };
        let weekOnNextMonth = {
          monthNumber: moment(dayWeekOnNextMonth?.[0]?._id).format("MM"),
          week: dayWeekOnNextMonth
        };
        if (!allResult.length) {
          if (!allResult[0]) {
            allResult[0] = { month: [] };
          }
          allResult[0].month.push(weekOnActualMonth);
          if (dayWeekOnNextMonth.length) {
            let allResultLength = allResult.length;
            if (!allResult[allResultLength]) {
              allResult[allResultLength] = { month: [] };
            }
            allResult[allResultLength].month.push(weekOnNextMonth);
          }
        } else {
          if (
            allResult[allResult.length - 1].month.some(
              (el) => el.monthNumber === weekOnActualMonth.monthNumber
            )
          ) {
            allResult[allResult.length - 1].month.push(weekOnActualMonth);
          } else {
            let allResultLength = allResult.length;
            if (!allResult[allResultLength]) {
              allResult[allResultLength] = { month: [] };
            }
            allResult[allResultLength].month.push(weekOnActualMonth);
          }
          if (dayWeekOnNextMonth.length) {
            if (
              allResult[allResult.length - 1].month.some(
                (el) => el.monthNumber === weekOnNextMonth.monthNumber
              )
            ) {
              allResult[allResult.length - 1].month.push(weekOnNextMonth);
            } else {
              let allResultLength2 = allResult.length;
              if (!allResult[allResultLength2]) {
                allResult[allResultLength2] = { month: [] };
              }
              allResult[allResultLength2].month.push(weekOnNextMonth);
            }
          }
        }
      }
    }
    for (let monthData of allResult) {
      let hoursMonthAgenda = 0;
      let minutesMonthAgenda = 0;
      let hoursMonthWorking = 0;
      let minutesMonthWorking = 0;
      for (let weekData of monthData.month) {
        // let totalWeekAgenda = 0;
        let hoursWeekAgenda = 0;
        let minutesWeekAgenda = 0;
        let hoursWeekWorking = 0;
        let minutesWeekWorking = 0;
        for (let dayData of weekData.week) {
          // let conge = dayData?.Conges?.[0]
          //   ? dayData?.Conges?.[0]
          //   : dayData?.Conges?.[1];
          // if (conge) {
          //   var start = moment.tz(conge.start, conge.timezone).toDate(),
          //     end = moment.tz(conge.end, conge.timezone).toDate(),
          //     currentDate = start,
          //     between = [];
          //   while (currentDate <= end) {
          //     between.push(currentDate);
          //     currentDate = moment(currentDate).add(1, "days").toDate();
          //   }
          //   var startFilter = queryParams.start,
          //     endfilter = queryParams.end,
          //     currentDatefilter = startFilter,
          //     betweenfilter = [];
          //   while (currentDatefilter <= endfilter) {
          //     betweenfilter.push(currentDatefilter);
          //     currentDatefilter = moment(currentDatefilter)
          //       .add(1, "days")
          //       .toDate();
          //   }
          //   dayData.Conges = [];
          //   for (let date of between) {
          //     for (let dateFilter of betweenfilter) {
          //       if (
          //         moment(date).format("YYYY-MM-DD") ===
          //         moment(dateFilter).format("YYYY-MM-DD")
          //       ) {
          //         let item = {
          //           ...conge,
          //           date: date,
          //         };
          //         dayData.Conges.push(item);
          //       }
          //     }
          //   }
          // }
          // console.log("day data conges : ", dayData.Conges);
          if (dayData.WorkingHours?.length) {
            let workingHoursData = [];
            let workingHoursDataIds = [];
            // let totalDay = 0;
            let duration = 0;
            let hours = 0;
            let minutes = 0;
            let formattedDuration = "";
            for (let data of dayData.WorkingHours) {
              if (data) {
                if (workingHoursData.length === 0) {
                  workingHoursData.push(data);
                  workingHoursDataIds.push(data._id);
                } else {
                  if (workingHoursDataIds.indexOf(data._id) < 0) {
                    workingHoursData.push(data);
                    workingHoursDataIds.push(data._id);
                  }
                }
              }
            }
            for (let data of workingHoursData) {
              const startMoment = moment(data.endDate);
              const endMoment = moment(data.startDate);
              duration += Math.round(
                startMoment.diff(endMoment, "minutes", true)
              );
              hours = Math.floor(duration / 60);
              minutes = duration % 60;
              formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
              if (data.endDate) {
                data.totalWorkingHours = formattedDuration;
              }
            }
            dayData.WorkingHours = workingHoursData;
            if (queryParams?.startFilter && queryParams?.endFilter) {
              let startFilter = getDateUtcZero(queryParams?.startFilter);
              let endFilter = moment(queryParams?.endFilter);
              if (
                moment(moment(dayData._id).set("hour", 12)).isBetween(
                  startFilter,
                  endFilter
                ) &&
                Boolean(hours + minutes)
              ) {
                dayData.totalDayWorkingHours = formattedDuration;
              } else {
                dayData.totalDayWorkingHours = "-";
              }
            } else {
              if (Boolean(hours + minutes)) {
                dayData.totalDayWorkingHours = formattedDuration;
              } else {
                dayData.totalDayWorkingHours = "-";
              }
            }
            dayData.dayNumber = moment(dayData._id).isoWeekday();
            hoursWeekWorking += hours;
            minutesWeekWorking += minutes;
          }
          if (Boolean(dayData.agenda?.length)) {
            // let agendasData = [];
            // let agendasDataIds = [];
            // let totalDay = 0;
            let agendasData = dayData.agenda;
            let duration = 0;
            let hours = 0;
            let minutes = 0;
            let formattedDuration = "";
            // for (let data of dayData.agenda) {
            //   if (data) {
            //     if (agendasData.length === 0) {
            //       agendasData.push(data);
            //       agendasDataIds.push(data._id);
            //     } else {
            //       if (agendasDataIds.indexOf(data._id) < 0) {
            //         agendasData.push(data);
            //         agendasDataIds.push(data._id);
            //       }
            //     }
            //   }
            // }
            for (let data of agendasData) {
              duration += Math.round(
                moment(data.endDate).diff(
                  moment(data.startDate),
                  "minutes",
                  true
                )
              );
              if (data.lunchHour?.startLunch && data.lunchHour?.endLunch) {
                duration -= Math.round(
                  moment(data.lunchHour.endLunch).diff(
                    moment(data.lunchHour.startLunch),
                    "minutes",
                    true
                  )
                );
              }
              hours = Math.floor(duration / 60);
              minutes = duration % 60;
              formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
            }
            dayData.agenda = agendasData;
            if (queryParams?.startFilter && queryParams?.endFilter) {
              let startFilter = getDateUtcZero(queryParams?.startFilter); //.subtract(
              //   1,
              //   "days"
              // );
              let endFilter = moment(queryParams?.endFilter); //.subtract(
              //   1,
              //   "days"
              // );
              if (
                moment(moment(dayData._id).set("hour", 12)).isBetween(
                  startFilter,
                  endFilter
                ) &&
                Boolean(hours + minutes)
              ) {
                dayData.totalDayPlannedHours = formattedDuration;
              } else {
                dayData.totalDayPlannedHours = "-";
              }
            } else {
              if (Boolean(hours + minutes)) {
                dayData.totalDayPlannedHours = formattedDuration;
              } else {
                dayData.totalDayPlannedHours = "-";
              }
            }
            // totalWeekAgenda += totalDay;
            hoursWeekAgenda += hours;
            minutesWeekAgenda += minutes;
          }
        }
        hoursWeekAgenda += Math.trunc(minutesWeekAgenda / 60);
        minutesWeekAgenda = minutesWeekAgenda % 60;
        weekData.totalWeekAgenda =
          hoursWeekAgenda +
          ":" +
          (minutesWeekAgenda < 10 ? "0" : "") +
          minutesWeekAgenda;
        hoursMonthAgenda += hoursWeekAgenda;
        minutesMonthAgenda += minutesWeekAgenda;

        hoursWeekWorking += Math.trunc(minutesWeekWorking / 60);
        minutesWeekWorking = minutesWeekWorking % 60;
        weekData.totalWeekWorking =
          hoursWeekWorking +
          ":" +
          (minutesWeekWorking < 10 ? "0" : "") +
          minutesWeekWorking;

        weekData.weekMonth = weekData.week?.[0]?._id;
        hoursMonthWorking += hoursWeekWorking;
        minutesMonthWorking += minutesWeekWorking;
        weekData.week.sort((a, b) =>
          a._id > b._id ? 1 : b._id > a._id ? -1 : 0
        );
      }
      hoursMonthAgenda += Math.trunc(minutesMonthAgenda / 60);
      minutesMonthAgenda = minutesMonthAgenda % 60;
      monthData.totalMonthAgenda =
        hoursMonthAgenda +
        ":" +
        (minutesMonthAgenda < 10 ? "0" : "") +
        minutesMonthAgenda;

      hoursMonthWorking += Math.trunc(minutesMonthWorking / 60);
      minutesMonthWorking = minutesMonthWorking % 60;
      monthData.totalMonthWorking =
        hoursMonthWorking +
        ":" +
        (minutesMonthWorking < 10 ? "0" : "") +
        minutesMonthWorking;

      greatTotal.hoursAgenda += hoursMonthAgenda;
      greatTotal.minutesAgenda += minutesMonthAgenda;
      greatTotal.hoursWorking += hoursMonthWorking;
      greatTotal.minutesWorking += minutesMonthWorking;
    }
    const hoursAgenda =
      greatTotal.hoursAgenda + Math.trunc(greatTotal.minutesAgenda / 60);
    const minutesAgenda = greatTotal.minutesAgenda % 60;
    greatTotal.totalAgenda =
      hoursAgenda + ":" + (minutesAgenda < 10 ? "0" : "") + minutesAgenda;

    const hoursWorking =
      greatTotal.hoursWorking + Math.trunc(greatTotal.minutesWorking / 60);
    const minutesWorking = greatTotal.minutesWorking % 60;
    greatTotal.totalWorking =
      hoursWorking + ":" + (minutesWorking < 10 ? "0" : "") + minutesWorking;
    greatTotal.personnalInformation =
      response.data.data?.[0]?.personnalInformation;
    greatTotal.User = response.data.data[0].User;
    const employeHours = allResult || [];
    return {
      employeHours,
      greatTotal,
      queryParams,
      lockedDays: responses?.[1] || []
    };
  }
};

export const getAssignationByProject = async (idEmployer, queryParams) => {
  const [employeProjects, totalHours] = await Promise.all([
    Project.getByEmployer(idEmployer, queryParams),
    Formation.getByEmployer(idEmployer, {
      ...queryParams,
      localTimezone: moment.tz.guess()
    }),
    EmployerHours.getByEmployerAndDate(idEmployer, {
      ...queryParams,
      localTimezone: moment.tz.guess()
    })
  ]);
  return {
    employeProjects,
    totalHours
  };
};

export const getEmployerHoursByEnterpriseWithOutFilter = async () => {
  if (localStorage.getItem("token")) {
    const response = await EmployerHours.getByEnterpriseWithOutFilter(
      localStorage.getItem("idAdminEnterprise") /*,
      { Employer: employers}*/
    );
    const employeHours = response?.[0]?.docs || [];
    return employeHours;
  }
};

// @deprecated
export const getOneWeekEmployerHoursByEmployer = (date) =>
  new Promise(async (resolve, reject) => {
    if (localStorage.getItem("token")) {
      const params = {
        date,
        employerId: localStorage.getItem("userId")
      };
      const response =
        await EmployerHours.getOneWeekEmployerHoursByEmployer(params);
      const count = response?.[0]?.count || 0;
      const employeHours = response?.data?.data || [];
      if (!employeHours) {
        reject(new Error("Unexepted response"));
      }
      const calendarUtils = new CalendarUtils();
      calendarUtils.setOptions({
        calculateSum: true,
        linear: "linear-gradient(60deg, #FA966B, #FCB698)"
      });
      for (let employeHour of employeHours) {
        let total = 0;
        const employerId = employeHour?.Employer?._id;
        for (let workingHour of employeHour.WorkingHours) {
          if (workingHour.endDate)
            total += moment
              .duration(moment(workingHour.endDate).diff(workingHour.startDate))
              .asHours();
        }
        let plannedHours = await horaryByEmployer(employerId);
        calendarUtils.setPlannedHours(plannedHours);
        const [plannedHour, emplacements, group] = await Promise.all([
          calendarUtils.getEvents(),
          getEmployerEmplacement(employerId),
          EmployeUtils.getGroupAndSubgroup(employerId, { title: true })
        ]);

        employeHour.plannedHoursEvents = plannedHour.eventWithLunch;
        employeHour.plannedHoursTotal = plannedHour.total.toFixed(2);
        employeHour.emplacements = emplacements;
        employeHour.groupes = group.groupAndSubGroups;
        employeHour.groupesText = group.groupText;
        employeHour.workingHourTotal = total.toFixed(2);
      }
      resolve({ employeHours, count });
    }
  });

export const getEmployerPlanneHours = async (employeId) => {
  try {
    let response = await HoraireService.getByEmployer(employeId);
    if (!response?.data?.data) {
      throw new Error("aucune donnée");
    }
    const { eventWithLunch, total } = await createEventFromHours(
      response?.data?.data,
      {
        total: true,
        linear: "linear-gradient(60deg, #FA966B, #FCB698)"
      }
    );
    return {
      events: eventWithLunch,
      total
    };
  } catch (err) {
    console.error("erreur chargement horaire => ", err);
    return {
      events: [],
      total: 0
    };
  }
};

// @deprecated
export const showWorkingHoursData = async (employeHours) => {
  if (employeHours?.length) {
    if (employeHours?.[0]?.Employer)
      employeHours.sort((employerA, employerB) =>
        compareEmployersFullName(employerA.Employer, employerB.Employer)
      );
    for (let employeHour of employeHours) {
      let totalWorkingHours = 0;
      for (let workingHour of employeHour?.WorkingHours) {
        if (workingHour.startDate && workingHour.endDate) {
          const startDate = moment(workingHour.startDate);
          const endDate = moment(workingHour.endDate);
          var duration = moment.duration(endDate.diff(startDate));
          var hours = duration.asHours();
          totalWorkingHours += hours;
        }
      }
      const employeId = employeHour?.Employer?._id;
      const [plannedHours, { groupAndSubGroups, groupText }] =
        await Promise.all([
          getEmployerPlanneHours(employeId),
          EmployeUtils.getGroupAndSubgroup(employeId, { title: true })
        ]);
      const valueEmplacement =
        await EmployerEmplacement.getByEmployer(employeId);
      if (totalWorkingHours % 1 === 0) {
        employeHour.workingHours = totalWorkingHours;
      } else {
        employeHour.workingHours = totalWorkingHours.toFixed(2);
      }
      if (plannedHours.total % 1 === 0) {
        employeHour.plannedHoursTotal = plannedHours?.total;
      } else {
        employeHour.plannedHoursTotal = plannedHours?.total.toFixed(2);
      }
      employeHour.emplacements = valueEmplacement.data?.data?.[0]?.Emplacement;
      employeHour.groupAndSubGroups = groupAndSubGroups;
      employeHour.groupText = groupText;
      employeHour.plannedHoursEvents = plannedHours.events;
      let nameEmplacements = employeHour?.emplacements?.length
        ? employeHour?.emplacements.map((name) => name?.name)
        : [];
      let names = nameEmplacements?.join(",");
      employeHour.nameEmplacements = names;
    }
    return employeHours;
  }
};

export const getEmployerHourByEmployer = async (employerId) => {
  if (localStorage.getItem("token")) {
    const response = await EmployerHours.getByEmployer(employerId);
    return response?.data?.data || null;
  }
};

export const getOneMonthEmployerHourByEmployer = async (params) => {
  if (localStorage.getItem("token")) {
    const response = await EmployerHours.getOneMonthByEmployer(params);
    return response?.data?.data || null;
  }
};

export const getOneWeekEmployerHourByEmployer = async (params) => {
  if (localStorage.getItem("token")) {
    const response = await EmployerHours.getOneWeekByEmployer(params);
    return response?.data?.data || null;
  }
};

export const getEmployerHoursOneWeekForCalendar = async (params) => {
  if (localStorage.getItem("token")) {
    try {
      const response =
        await EmployerHours.getEmployerHoursOneWeekForCalendar(params);
      return response?.data?.data || null;
    } catch (err) {
      console.log("Error getEmployerHoursOneWeekForCalendar: " + err);
    }
  }
};

export const getOneMonthHoraryForCalendarByEnteprise = (data, filter) =>
  new Promise(async (resolve, reject) => {
    if (localStorage.getItem("token")) {
      try {
        const enterpriseId = localStorage.getItem("enterpriseId");
        const params = {
          enterpriseId,
          date: data.date,
          timezone: data.timezone
        };
        filter.user = localStorage.getItem("userId");
        // filter.withoutLunchOnly = withoutLunchOnly;
        // if(timezone) filter.timezone = timezone;
        const response =
          await HoraireService.getOneMonthHoraryForCalendarByEnteprise(
            params,
            filter
          ).catch((error) => {
            console.error(error);
          });
        resolve(response?.data?.data || []);
      } catch (err) {
        console.log("Error getOneMonthHoraryForCalendarByEnteprise: " + err);
        resolve([]);
      }
    }
  });

export const getOneWeekHoraryForCalendarByEnteprise = (date, filter) =>
  new Promise(async (resolve, reject) => {
    if (localStorage.getItem("token")) {
      try {
        const enterpriseId = localStorage.getItem("enterpriseId");
        const res = await Users.getUserConnected(
          localStorage.getItem("userId")
        );
        if (res) {
          var timezone = res.data?.data?.timezoneEnterprise
            ? res.data?.data?.timezoneEnterprise
            : res.data?.data?.timezone;
        }
        filter.timezone = getTimezoneName(timezone);
        const params = {
          enterpriseId,
          date
        };
        const response =
          await HoraireService.getOneWeekHoraryForCalendarByEnteprise(
            params,
            filter
          );
        resolve(response?.data?.data || []);
      } catch (err) {
        console.log("Error getOneWeekHoraryForCalendarByEnteprise: " + err);
        resolve([]);
      }
    }
  });

// export const getOneMonthHoraryWithoutLunchForCalendarByEnteprise = (date, filter) =>
//   new Promise(async (resolve, reject) => {
//     if (localStorage.getItem("token")) {
//       try {
//         const enterpriseId = localStorage.getItem("enterpriseId");
//         const params = {
//           enterpriseId,
//           date,
//         };
//         const res = await Users.getUserConnected(
//           localStorage.getItem("userId")
//         );
//         if (res) {
//           var timezone = res.data?.data?.timezoneEnterprise? res.data?.data?.timezoneEnterprise : res.data?.data?.timezone ;
//         }
//         filter.timezone = getTimezoneName(timezone);
//         const response = await HoraireService.getOneMonthHoraryWithoutLunchForCalendarByEnteprise(
//           params,
//           filter
//         );
//         resolve(response?.data?.data || []);
//       } catch (err) {
//         console.log("Error getOneMonthHoraryForCalendarByEnteprise: " + err);
//         resolve([]);
//       }
//     }
//   });
// export const getOneMonthHoraryWithoutLunchForWeekDetails = (date, filter) =>
//   new Promise(async (resolve, reject) => {
//     if (localStorage.getItem("token")) {
//       try {
//         const enterpriseId = localStorage.getItem("enterpriseId");
//         const params = {
//           enterpriseId,
//           date,
//         };
//         const response = await HoraireService.getOneMonthHoraryWithoutLunchForWeekDetails(
//           params,
//           filter
//         );
//         resolve(response?.data?.data || []);
//       } catch (err) {
//         console.log("Error getOneMonthHoraryForCalendarByEnteprise: " + err);
//         resolve([]);
//       }
//     }
//   });

const hoursWithTimezone = (hour) => {
  hour.start = getDateToRender(hour.start, hour.timezone);
  hour.end = getDateToRender(hour.end, hour.timezone);
  if (Boolean(hour.lunchHour)) {
    hour.lunchHour.startLunch = getDateToRender(
      hour.lunchHour.startLunch,
      hour.timezone
    );
    hour.lunchHour.endLunch = getDateToRender(
      hour.lunchHour.endLunch,
      hour.timezone
    );
  }
  return hour;
};

export const getOneWeekForCalendarByEmployer = async (params) => {
  if (localStorage.getItem("token")) {
    try {
      const response =
        await HoraireService.getOneWeekForCalendarByEmployer(params);
      let hoursList = response?.data?.data || [];
      hoursList = hoursList.map((hour) => hoursWithTimezone(hour));
      return hoursList;
    } catch (err) {
      console.log("Error getOneWeekForCalendarByEmployer: " + err);
      return null;
    }
  }
};

export const getOneMonthForCalendarByEmployer = async (subParam) => {
  if (localStorage.getItem("token")) {
    try {
      const params = {
        userId: localStorage.getItem("userId"),
        ...subParam
      };
      const response =
        await HoraireService.getOneMonthForCalendarByEmployer(params);
      let hoursList = response?.data?.data || null;
      hoursList.hoursWithLunch = hoursList.hoursWithLunch?.map((hour) =>
        hoursWithTimezone(hour)
      );
      hoursList.hoursWithoutLunch = hoursList.hoursWithoutLunch?.map((hour) =>
        hoursWithTimezone(hour)
      );
      return hoursList;
    } catch (err) {
      console.log("Error getOneMonthForCalendarByEmployer: " + err);
      return null;
    }
  }
};

// export const getHoraryWithoutLunchOneMonthForCalendarByEmployer = async (
//   subParam
// ) => {
//   if (localStorage.getItem("token")) {
//     try {
//       const params = {
//         employerId: localStorage.getItem("userId"),
//         ...subParam,
//       };
//       const response = await HoraireService.getHoraryWithoutLunchOneMonthForCalendarByEmployer(
//         params
//       );
//       return response?.data?.data || null;
//     } catch (err) {
//       console.log(
//         "Error getHoraryWithoutLunchOneMonthForCalendarByEmployer: " + err
//       );
//       return null;
//     }
//   }
// };

export const getHoraryByEmployerBetweenTwoDateForCalendar = async (
  startDate,
  endDate
) => {
  if (localStorage.getItem("token")) {
    try {
      const params = {
        employerId: localStorage.getItem("userId"),
        startDateParam: startDate,
        endDateParam: endDate
      };
      const response = await HoraireService.getHoraryBetweenTwoDate(params);
      let listHours = response?.data?.data || [];
      listHours = listHours.map((hour) => hoursWithTimezone(hour));
      return listHours;
    } catch (err) {
      console.log("Error getByEmployerBetweenTwoDate: " + err);
    }
  }
};

export const getWorkingHoursByEmployerBetweenTwoDateForCalendar = async (
  startDate,
  endDate
) => {
  if (localStorage.getItem("token")) {
    try {
      const params = {
        employerId: localStorage.getItem("userId"),
        startDateParam: startDate,
        endDateParam: endDate
      };
      const response =
        await EmployerHours.getByEmployerBetweenTwoDateForCalendar(params);
      return response?.data?.data || null;
    } catch (err) {
      console.log("Error getByEmployerBetweenTwoDate: " + err);
    }
  }
};

export const getHoraryWithoutLunchByEmployerBetweenTwoDate = async (
  startDate,
  endDate
) => {
  if (localStorage.getItem("token")) {
    try {
      const params = {
        employerId: localStorage.getItem("userId"),
        startDateParam: startDate,
        endDateParam: endDate
      };
      const response =
        await HoraireService.getHoraryWithoutLunchByEmployerBetweenTwoDate(
          params
        );
      return response?.data?.data || null;
    } catch (err) {
      console.log(
        "Error getHoraryWithoutLunchByEmployerBetweenTwoDate: " + err
      );
    }
  }
};

//Get TimeClock By Enterprise
export const getTimeClockByEnterprise = async (queryParams) => {
  if (localStorage.getItem("token")) {
    const enterpriseId = localStorage.getItem("enterpriseId");
    const response = await TimeClock.getByEnterprise(enterpriseId, queryParams);
    if (response?.[0]?.docs) {
      return { timeclocks: response?.[0]?.docs, count: response?.[0]?.count };
    } else {
      return { timeclocks: [], count: 0 };
    }
  }
};

//Get Enterprise by id
export const getByEnterpriseId = async () => {
  if (localStorage.getItem("token")) {
    const response = await Enterprise.getById(
      localStorage.getItem("enterpriseId")
    );
    if (response?.data?.data?.data) {
      return response.data.data.data;
    }
  }
};

//Get Datafile by Enterprise
export const getDatafileByEnterprise = async () => {
  if (localStorage.getItem("token")) {
    let logos = {};
    const rep = (
      await DataFile.getDocumentsByEntreprise(
        localStorage.getItem("enterpriseId")
      )
    )?.data?.data?.data;
    if (rep && Array.isArray(rep)) {
      const logo = rep.find((el) => el.fileType !== "logo100");
      const logo100 = rep.find((el) => el.fileType === "logo100");
      logos = { logo, logo100 };
    }
    return logos || {};
  }
};

export const getCongeByEnterpriseAndFilter = async (params) => {
  if (localStorage.getItem("token")) {
    try {
      const result = await Conge.getByEnterpriseAndFilter(
        localStorage.getItem("idAdminEnterprise"),
        params
      );
      if (result) {
        const { startDate: startFilter, endDate: endFilter } = params.filters;
        if (result?.[0]?.docs && startFilter && endFilter) {
          result[0].docs = result[0].docs.filter((doc) => {
            const start = getDateToRender(doc.start, doc.timezone),
              end = getDateToRender(doc.end, doc.timezone);
            return !(
              moment(end).isBefore(startFilter) ||
              moment(start).isAfter(endFilter)
            );
          });
        }
        return result;
      }
    } catch (err) {
      console.log(err);
    }
  }
};

export const getCongeByEmployerAndFilter = async (params) => {
  if (localStorage.getItem("token")) {
    try {
      const result = await Conge.getByEmployerAndFilter(
        localStorage.getItem("userId"),
        params
      );
      if (result) {
        const { startDate: startFilter, endDate: endFilter } = JSON.parse(
          params.filters
        );
        if (result?.[0]?.docs && startFilter && endFilter) {
          result[0].docs = result[0].docs.filter((doc) => {
            const start = getDateToRender(doc.start, doc.timezone),
              end = getDateToRender(doc.end, doc.timezone);
            return !(
              moment(end).isBefore(startFilter) ||
              moment(start).isAfter(endFilter)
            );
          });
        }
        return result;
      }
    } catch (err) {
      console.log(err);
    }
  }
};

// export const getEmployersInfoForStat = async (params) => {
//   if (localStorage.getItem("token")) {
//     try{
//       const employers = await Employer.getEmployersForStat(localStorage.getItem('userId'), params);
//       if (employers) {
//         return employers;
//       }
//     }catch(err){
//       console.log(err);
//     }
//   }
// }

export const getSalaryStatistics = async (params = {}, filter) => {
  try {
    let response = await Employer.getSalaryStatistics(
      localStorage.getItem("idAdminEnterprise"),
      params,
      filter
    );

    return response?.data?.data?.[0] || [];
  } catch (err) {
    console.error(err);
    return null;
  }
}; 
export const getSalaryEvolutions = async (params = {}, filter) => {
  try {
    let response = await Employer.getSalaryEvolution(
      localStorage.getItem("idAdminEnterprise"),
      params,
      filter,
    );

    return response?.data?.data || [];
  } catch (err) {
    console.error(err);
    return null;
  }
};
export const getSalaryEvolutionsByYears = async (params = {}, filter) => {
  try {
    if(params.years?.length){
      params = {
        ...params,
        years: Array.from({length: params.years[1] - params.years[0] + 1}, (v,i)=> params.years[0] + i)
      }
    }
    let response = await Employer.getSalaryEvolutionsByYears(
      localStorage.getItem("idAdminEnterprise"),
      params,
      filter,
    );

    return response?.data?.data || [];
  } catch (err) {
    console.error(err);
    return null;
  }
};
export const getEmployeStatistics = async (filter, params) => {
  try {
    let response = await Employer.getEmployeStatistics(
      localStorage.getItem("idAdminEnterprise"),
      filter,
      params
    );
    response = response?.data?.data;
    if (!response) {
      throw new Error("Erreur chargement statistique");
    }
    /* -- Formattage de données côté front -- */
    //parity

    const parity = response.parity[0];
    const parityData = [
      {
        name: parity?.female?.name || "Femmes",
        backgroundColor: "#36a2eb",
        count: parity?.female?.value?.length || 0,
        additional: parity?.female?.count || 0
      },
      {
        name: parity?.male?.name || "Hommes",
        backgroundColor: "#4cc0c0",
        count: parity?.male?.value?.length || 0,
        additional: parity?.male?.count || 0
      },
      {
        name: parity?.other?.name || "Non spécifié",
        backgroundColor: "#ffcc56",
        count: parity?.other?.value?.length || 0,
        additional: parity?.other?.count || 0
      }
    ];
    const _rotationData = response?.rotation?.[0];
    return {
      count: response.count,
      parity: parityData,
      rotation: {
        hiringDates: _rotationData?.graphData[0]?.hired || [],
        endDates: _rotationData?.graphData[0].quitted || [],
        employePerMonth: _rotationData?.graphData?.[0].active || [],
        details: _rotationData?.details
      },
      antiquity: {
        list: response.antiquity?.[0]?.graphData?.[0],
        details: response?.antiquity?.[0]?.details?.[0]
      },
      ages: {
        list: response.ages[0]?.graphData?.[0]?.docs,
        details: response?.ages?.[0]?.details?.[0]
      },
      agesCategories: response?.ageCategories,
      conges: response.conges,
      getByLocations: response?.getByLocations,
      getByCountries: response?.getByCountries,
      getByTown: response?.getByTown,
      getByLanguages: response?.getByLanguages,
      getByPosts: response?.getByPosts,
      unavalaibility: response?.unavailability?.graphData,
      demissionReason: response?.demissionReason?.graphData,
      foodPreferences: response?.foodPreferences,
      allergies: response?.allergies
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};

//Get list Material by Enterprise
export const getMaterialsByEnterprise = async (queryParams) => {
  if (localStorage.getItem("token")) {
    // let materials = [];
    // let count = 0;
    try {
      const getMaterialResponse = await Material.getListByEnterprise(
        localStorage.getItem("enterpriseId"),
        queryParams
      );
      if (getMaterialResponse) {
        return getMaterialResponse;
      }
      // materials = getMaterialResponse?.[0]?.docs;
      // count = getMaterialResponse?.[0]?.count;
    } catch (err) {
      console.log(err);
    }
    // return { materials, count };
  }
};

export const getCategoryMaterialByEnterprise = async () => {
  if (localStorage.getItem("token")) {
    let categories = [];
    try {
      const getCategoryResponse = await Material.getListCategoryByEnterprise(
        localStorage.getItem("enterpriseId")
      );
      if (getCategoryResponse.length === 0) {
        throw new Error("no category found");
      }
      categories = getCategoryResponse;
    } catch (err) {
      console.error("error loading category =>", err);
    }
    return { categories };
  }
};

export const checkMaterial = async (queryParams) => {
  if (localStorage.getItem("token")) {
    let isMaterialExist = false;
    try {
      const getcheckResponse = await Material.checkMaterial(
        localStorage.getItem("enterpriseId"),
        queryParams
      );
      isMaterialExist = getcheckResponse;
    } catch (err) {
      console.error("error checking material =>", err);
    }
    return isMaterialExist;
  }
};

export const getEmployerEmployers = async (query) => {
  try {
    let response = await Employer.getByEmployerAccess(
      localStorage.getItem("userId"),
      query
    );
    return response;
  } catch (err) {
    console.error("error getting employers by employer access =>", err);
  }
};
