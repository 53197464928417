import {
  getTabValue,
  getPermissionTree,
  getPermissionValue,
  combineObjectAndClone,
  getAllPermissionValue
} from "@helpers/_functions";

const dataEmployer = {
  dashboard: {
    value: "DDashboard",
    defaultActive: "active",
    label: "Tableau De Bord"
  },
  mySchedule: {
    label: "Mon horaire",
    value: "myhours",
    children: [
      {
        value: "DWorkingHours",
        defaultActive: "active",
        label: "Horaires de travail"
      },
      {
        value: "DWolidayAndAbsences",
        defaultActive: "active",
        label: "Vacances et absences"
      }
    ]
  },
  trackingHours: {
    value: "DHoursSuivi",
    defaultActive: "active",
    label: "Suivi des heures"
  },
  news: {
    label: "Nouvelles",
    value: "DNews"
  },
  myProfile: {
    label: "Ma fiche employé",
    value: "DMyfiche",
    children: [
      {
        value: "DInfopers",
        defaultActive: "active",
        label: "Informations personnelles"
      },
      {
        value: "DEmploisalary",
        defaultActive: "active",
        label: "Emploi, salaire et vacances"
      },
      {
        // value: "DCoursCertify",
        value: "DDocument",
        defaultActive: "active",
        label: "Documents"
      },
      {
        value: "DCertFormation",
        defaultActive: "active",
        label: "Permis et formations"
      },
      {
        value: "DMaterial",
        defaultActive: "active",
        label: "Matériel assigné"
      },
      {
        label: "Paramètres du compte",
        value: "DParameters",
        defaultActive: "active"
      }
    ]
  },
  assignedTask: {
    label: "Tâches",
    value: "tasks",
    children: [
      {
        value: "DAssignedTask",
        defaultActive: "active",
        label: "Liste"
      }
    ]
  },
  assessmentDevelopmentPlan: {
    label: "Évaluations et plan de développement",
    value: "DAssessmentDevelopmentPlan"
  },
  expensesNotes: {
    label: "Remboursements",
    value: "DExpensesNotes"
  },
  documentation: {
    label: "Documentation",
    value: "documentation",
    children: [
      {
        value: "DDocumentation",
        defaultActive: "active",
        label: "Documentation"
      },
      {
        value: "DOrganigram",
        defaultActive: "active",
        label: "Organigramme"
      }
    ]
  }
  // documentation: {
  //   label: "Documentation",
  //   value: "DDocumentation"
  // }
};

const dataEntreprise = {
  employees: {
    label: "Employés",
    value: "listEmployer",
    children: [
      {
        label: "Liste",
        value: "listBuild",
        defaultActive: "active",
        childs: {
          employeeFiles: {
            label: "Dossiers Employés",
            value: "employeeFiles",
            children: [
              {
                value: "DListInfopers",
                defaultActive: "active",
                label: "Informations personnelles"
              },
              {
                label: "Emploi, salaire et vacances",
                value: "DListEmploisalary",
                defaultActive: "active"
              },
              {
                label: "Documents et notes",
                // value: "DListCoursCertify",
                value: "DListDocumentNote",
                defaultActive: "active"
              },
              {
                label: "Permis et formations",
                value: "DListCertFormation",
                defaultActive: "active"
              },
              {
                label: "Matériel assigné",
                value: "DListMaterial",
                defaultActive: "active"
              },
              {
                label: "Paramètres du compte",
                value: "DListParameters",
                defaultActive: "active"
              }
            ]
          }
        }
      },
      {
        value: "statistiquesBuild",
        defaultActive: "active",
        label: "Statistiques"
      },
      {
        value: "anniversaryBuild",
        defaultActive: "active",
        label: "Anniversaires"
      },
      {
        value: "beginEnd",
        defaultActive: "active",
        label: "Arrivées et départs"
      },
      {
        value: "indisponibility",
        defaultActive: "active",
        label: "Indisponibilités"
      }
    ]
  },
  schedulePlanning: {
    label: "Planification des horaires",
    value: "hoursPlan",
    children: [
      {
        value: "hoursBuild",
        defaultActive: "active",
        label: "Horaires de travail"
      },
      {
        value: "vacancyAbsence",
        defaultActive: "active",
        label: "Vacances et absences"
      }
    ]
  },
  workedHours: {
    label: "Heures travaillées",
    value: "hoursWorked",
    children: [
      {
        value: "compteRendu",
        defaultActive: "active",
        label: "Compte rendu"
      },
      {
        value: "recapitulatifMensuel",
        defaultActive: "active",
        label: "Récapitulatif mensuel"
      },
      {
        value: "repartition",
        defaultActive: "active",
        label: "Répartition par projets"
      },
      {
        value: "saisieDesHeuresEtPointage",
        defaultActive: "active",
        label: "Saisie des heures et pointages"
      }
    ]
  },
  tasks: {
    label: "Tâches",
    value: "tasks"
  },
  expensesNotes: {
    label: "Remboursements",
    value: "expensesNotes"
  },
  news: {
    label: "Nouvelles",
    value: "news"
  },
  documentation: {
    label: "Documentation",
    value: "documentation",
    children: [
      {
        value: "documents",
        defaultActive: "active",
        label: "Documents"
      }
      // {
      //   value: "organigramme",
      //   defaultActive: "active",
      //   label: "Organigramme"
      // }
    ]
  },
  formations: {
    label: "Formations et certifications",
    value: "formationEnterprise",
    children: [
      {
        value: "formations",
        defaultActive: "active",
        label: "Formations"
      },
      {
        value: "certifications",
        defaultActive: "active",
        label: "Certifications"
      }
    ]
  },
  recruitments: {
    label: "Recrutements",
    value: "recrutements",
    children: [
      {
        value: "availablePostes",
        defaultActive: "active",
        label: "Postes à pourvoir"
      },
      {
        value: "applications",
        defaultActive: "active",
        label: "Recrues"
      },
      {
        value: "cvBank",
        defaultActive: "active",
        label: "Banque de CVs"
      },
      {
        value: "modele",
        defaultActive: "active",
        label: "Modèles"
      },
      {
        value: "careerPage",
        defaultActive: "active",
        label: "Site Carrière"
      },
      {
        value: "statistics",
        defaultActive: "active",
        label: "Statistiques"
      }
    ]
  },
  materials: {
    label: "Matériels",
    value: "material",
    children: [
      {
        value: "materialDashboard",
        defaultActive: "active",
        label: "Dashboard"
      },
      {
        value: "materialInventory",
        defaultActive: "active",
        label: "Inventaire"
      }
    ]
  },
  assesments: {
    label: "Évaluations",
    value: "assesments",
    children: [
      {
        value: "skillsState",
        defaultActive: "active",
        label: "État des compétences"
      },
      {
        value: "performance",
        defaultActive: "active",
        label: "Performances"
      },
      {
        value: "assignmentsFrequency",
        defaultActive: "active",
        label: "Attributions et fréquences des évaluations"
      },
      {
        value: "assessmentsHistory",
        defaultActive: "active",
        label: "Historique"
      }
    ]
  },
  developmentPlan: {
    label: "Plan de développement",
    value: "DevelopmentPlan"
  },
  settings: {
    label: "Paramètres",
    value: "parameters",
    children: [
      {
        value: "entreprise",
        defaultActive: "active",
        label: "Entreprise"
      },
      {
        value: "postsGroupsRolesSettings",
        defaultActive: "active",
        label: "Postes, groupes et gestion des accès"
      },
      {
        value: "calendar",
        defaultActive: "active",
        label: "Calendrier"
      },
      {
        value: "workingHoursSettings",
        defaultActive: "active",
        label: "Heures travaillées"
      },
      {
        value: "expensesNotesSettings",
        defaultActive: "active",
        label: "Remboursements"
      },
      {
        value: "recruitmentAts",
        defaultActive: "active",
        label: "Recrutement"
      },
      {
        value: "assismentSettings",
        defaultActive: "active",
        label: "Évaluations"
      }
    ]
  }
};

export const permissionTreeTranslator = (data = [], translate = (a) => a) =>
  data.map((item) => {
    item.label = translate(item.label);
    if (item.children?.length) {
      item.children = permissionTreeTranslator(item.children, translate);
    }
    return item;
  });

export const permissionEmployeeLists = getAllPermissionValue(dataEmployer);
export const permissionEnterpriseLists = getAllPermissionValue(dataEntreprise);
export const employeeMenuPermission = getPermissionValue(dataEmployer);
export const enterpriseMenuPermission = getPermissionValue(dataEntreprise);
export const permissionEmployeeTabs = getTabValue(dataEmployer);
export const permissionEnterpriseTabs = getTabValue(dataEntreprise);
export const permissionEmployeeTree = getPermissionTree(
  dataEmployer,
  employeeMenuPermission
);
export const permissionEnterpriseTree = getPermissionTree(
  dataEntreprise,
  enterpriseMenuPermission
);

export const permissions = combineObjectAndClone(
  {
    ...employeeMenuPermission,
    employee: permissionEmployeeLists
  },
  {
    ...enterpriseMenuPermission,
    enterprise: permissionEnterpriseLists
  }
);
/*
permissions = {
      "mySchedule": [
          "DWorkingHours",
          "DWolidayAndAbsences"
      ],
      "trackingHours": [
          "DHoursSuivi"
      ],
      "news": [
          "news"
      ],
      "myProfile": [
          "DInfopers",
          "DEmploisalary",
          "DCoursCertify",
          "DMaterial",
          "DParameters"
      ],
      "assignedTask": [
          "DAssignedTask",
      ],
      "employee": [
          "DWorkingHours",
          "DWolidayAndAbsences",
          "DHoursSuivi",
          "DNews",
          "DInfopers",
          "DEmploisalary",
          "DCoursCertify",
          "DMaterial",
          "DParameters",
          "DAssignedTask",
      ],
      "employeeFiles": [
          "DListInfopers",
          "DListEmploisalary",
          "DListCoursCertify",
          "DListMaterial",
          "DListParameters"
      ],
      "employees": [
          "listBuild",
          "statistiquesBuild",
          "anniversaryBuild",
          "beginEnd"
      ],
      "schedulePlanning": [
          "hoursBuild",
          "vacancyAbsence"
      ],
      "workedHours": [
          "compteRendu",
          "recapitulatifMensuel",
          "repartition",
          "saisieDesHeuresEtPointage"
      ],
      "recruitments": [
          "availablePostes",
          "applications",
          "cvBank",
          "modele",
          "careerPage"
      ],
      "materials": [
          "materialDashboard",
          "materialInventory"
      ],
      "settings": [
          "entreprise",
          "postsGroupsRolesSettings",
          "calendar",
          "assismentSettings",
          "expensesNotesSettings",
          "recruitmentAts"
      ],
      "assesments": [
          "skillsState",
          "performance",
          "assignmentsFrequency",
      ],
      "enterprise": [
          "DListInfopers",
          "DListEmploisalary",
          "DListCoursCertify",
          "DListMaterial",
          "DListParameters",
          "listBuild",
          "statistiquesBuild",
          "anniversaryBuild",
          "beginEnd",
          "hoursBuild",
          "vacancyAbsence",
          "compteRendu",
          "recapitulatifMensuel",
          "repartition",
          "saisieDesHeuresEtPointage",
          "news",
          "availablePostes",
          "applications",
          "cvBank",
          "modele",
          "careerPage",
          "materialDashboard",
          "materialInventory",
          "entreprise",
          "postsGroupsRolesSettings",
          "calendar",
          "skillsState",
          "performance",
          "assignmentsFrequency",
          "assismentSettings"
      ]
  }
*/
